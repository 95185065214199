import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/layout";

const Eteleink = ({ data }) => (
  <Layout>
    <div className="container content-wrap">
      <div className="row my-3">
        <div className="col">
          <h1>Ételeink</h1>
        </div>
      </div>

      <div className="row my-1">
        <div className="col">
          <h2>Heti ebéd menü</h2>
        </div>
      </div>

      <div className="row">
        {data?.allMenu?.edges.map((menu, index) => {
          return (
            <div className="col-12" key={index}>
              <GatsbyImage
                image={getImage(menu.node.image)}
                loading="eager"
                imgStyle={{ objectFit: "contain" }}
                alt="Heti menü"
                key={menu.node.id}
                className="img-fluid rounded"
              />
            </div>
          );
        })}
      </div>

      <div className="row my-1">
        <div className="col">
          <h2>Kiszállítás</h2>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <p>
            Ételrendelés Wolt és FoodPanda alkalmazásokon keresztül hétköznap
            11.30-14.30 között.
          </p>
        </div>
      </div>

      {/*<div className="row">*/}
      {/*  <div className="col">*/}
      {/*    <p className="text-justify">*/}
      {/*      Hétköznap 11.30-tól vegetáriánus ebédmenüvel, glutén- és*/}
      {/*      laktózmentes opciókkal, vegán fogásokkal várunk.*/}
      {/*    </p>*/}
      {/*    <p className="text-justify">*/}
      {/*      Szombaton 9-től házi péksütik, ínycsiklandó bagelek, wrapok és vegán*/}
      {/*      tapas tálak szerepelnek a kínálatunkban, valamint ebédmenünk is van*/}
      {/*      déltől. (Nyári szombatokon zárva vagyunk!)*/}
      {/*    </p>*/}
      {/*    <p className="text-justify">*/}
      {/*      Ételeink a napi készlet erejéig érhetőek el. Csak készpénzt tudunk*/}
      {/*      elfogadni!*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <hr className="featurette-divider" />

      <div className="row">
        <div className="col">
          <h2>Egyéb ételeink</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 my-3">
          <div className="card">
            <GatsbyImage
              className="card-img-top"
              alt="Harapnivalók"
              image={getImage(data.food1)}
            />

            <div className="card-body">
              <h2 className="card-title">Harapnivalók</h2>
              <ul>
                <li>Házi péksütik: pogik, vegán csigák</li>
                <li>
                  Egyéb péksüti: aktuális kínálat szerint barackos-mandulás
                  fonott, fahéjas-kardamomos fonott
                </li>
                <li>Bagelek</li>
                <li>Sós és édes pite napi ajánlat szerint</li>
                <li>Mártogatósok, zöldségkrémek szezonálisan.</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-3">
          <div className="card">
            <GatsbyImage
              className="card-img-top"
              alt="Édességek"
              image={getImage(data.food2)}
            />

            <div className="card-body">
              <h2 className="card-title">Édességek</h2>
              <p className="card-text">
                Nyers vegán sütiszeletek aktuális kínálat szerint (6-8 féle
                mindig akad a pultban).
              </p>
              <p className="card-text">
                Mind tej-, tojás-, glutén-, laktóz-, cukormentesek. Ízelítő
                nyers vegán tortáinkból <Link to="/tortarendeles">itt</Link>.
              </p>
              <ul>
                <li>Nyers vegán kókuszgolyó</li>
                <li>Chia puding, tápióka puding</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-lg-4 my-3">
          <div className="card">
            <GatsbyImage
              className="card-img-top"
              alt="Kávé, tea, italok"
              image={getImage(data.food3)}
            />
            <div className="card-body">
              <h2 className="card-title">Kávé, tea, italok</h2>
              <p className="card-text">
                Kávéink francia pörkölésű, Monterosa kávébabból készülnek,
                Erberling Judit magyar szakember pörköli kézműves pörköléssel,
                körültekintően odafigyelve arra, milyen alapanyagokat használ.
              </p>
              <ul>
                <li>presszó</li>
                <li>hosszú kávé</li>
                <li>cappuccino</li>
                <li>tejeskávé/latte</li>
                <li>melange</li>
                <li>házi szörp</li>
                <li>Funky Forest gyümölcslé</li>
                <li>tea</li>
              </ul>
              <h4>Szezonális italok</h4>
              <ul>
                <li>Forró csoki</li>
                <li>Forró alma</li>
                <li>Forró homoktövis-alma</li>
                <li>Chai latte</li>
              </ul>
              <p className="card-text">
                Kávéinkat kérheted koffeinmentes változatban és laktózmentes
                tejjel, rizstejjel, zabtejjel
              </p>
              <p className="card-text">
                Elviteles kávéinkra – amennyiben nem saját bögrédbe, poharadba
                töltjük – plusz 50 Ft-ot számolunk fel.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export const query = graphql`
  query {
    allMenu {
      edges {
        node {
          id
          image {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
    food1: file(relativePath: { eq: "foods/reggeli.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    food2: file(relativePath: { eq: "foods/sutik.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
    food3: file(relativePath: { eq: "foods/kave.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;

export const Head = () => <title>Szelence Café | Ételeink</title>;

export default Eteleink;
